import { Component, Input } from '@angular/core';
import { scaleRevealAnimation } from 'src/app/animations';

@Component({
  selector: 'app-reservables-warnings-template',
  templateUrl: './reservables-warnings-template.component.html',
  styleUrls: [ './reservables-warnings-template.component.scss' ],
  animations: [ scaleRevealAnimation ],
})
export class ReservablesWarningsTemplateComponent {
  @Input({ required: true })
  public reservableWarnings!: Map<string, string[]>;

  public expanded = false;
}
