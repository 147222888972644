import { Component, Input, OnChanges } from '@angular/core';
import { Observable, from, map, of } from 'rxjs';
import { SimpleChangesTyped } from 'src/app/components/simple-changes-typed';
import { Building, ContactInformation } from 'src/app/model/building';
import { ResourcesApiService } from 'src/app/services/api/resources-api.service';

@Component({
  selector: 'app-contact-template',
  templateUrl: './contact-template.component.html',
  styleUrls: [ './contact-template.component.scss' ],
})
export class ContactTemplateComponent implements OnChanges {
  @Input()
  public building?: Building;

  public contactInformation$: Observable<ContactInformation | undefined> = of(undefined);

  constructor(private readonly resourcesApi: ResourcesApiService) {}

  public ngOnChanges(changes: SimpleChangesTyped<ContactTemplateComponent>): void {
    if (changes.building) {
      this.initContactInformation(changes.building.currentValue);
    }
  }

  private initContactInformation(building: Building | undefined) {
    if (building) {
      this.contactInformation$ = from(this.resourcesApi.getContactInformation(building)).pipe(
        map(contactInformation => contactInformation ?? undefined),
      );
    } else {
      this.contactInformation$ = of(undefined);
    }
  }
}
