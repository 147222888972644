<button mat-button type="button" color="primary" class="normal-case" (click)="expanded = !expanded">
  <mat-icon [class.rotate-180]="expanded">expand_more</mat-icon>
  {{ 'bkwa.reservables.reservablesCommitted.showMoreButton' | translate }}
</button>
@if (expanded) {
  <div class="expandable-content pt-2" @scaleRevealAnimation>
    @for (reservableWarning of reservableWarnings | keyvalue; track reservableWarning) {
      <div>
        <strong>{{ reservableWarning.key }}</strong> -
        @for (warning of reservableWarning.value; track warning; let last = $last) {
          {{ warning | translate }}
          @if (!last) {
            |
          }
        }
      </div>
    }
  </div>
}
