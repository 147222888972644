import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../../shared.module';
import { UserInfoModule } from '../user-info/user-info.module';
import { ModalConfirmComponent } from './confirm/confirm.component';
import { ModalErrorComponent } from './error/error.component';
import { ModalExternalComponent } from './modal-external/modal-external.component';
import { ModalSelectComponent } from './select/select.component';
import { ContactTemplateComponent } from './templates/contact-template/contact-template.component';
import { ReservablesWarningsTemplateComponent } from './templates/reservables-warnings-template/reservables-warnings-template.component';
import { ModalUserComponent } from './user/user.component';

@NgModule({
  declarations: [
    ModalSelectComponent,
    ModalConfirmComponent,
    ModalUserComponent,
    ModalErrorComponent,
    ContactTemplateComponent,
    ModalExternalComponent,
    ReservablesWarningsTemplateComponent,
  ],
  imports: [ SharedModule, MatDialogModule, UserInfoModule ],
  exports: [ ModalConfirmComponent, ContactTemplateComponent, ReservablesWarningsTemplateComponent ],
})
export class ModalModule {}
