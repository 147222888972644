@if (contactInformation$ | async; as contactInformation) {
  <div class="contact">
    @if (contactInformation.name) {
      <div>{{ contactInformation.name }}</div>
    }
    @if (contactInformation.email) {
      <div>
        <a href="mailto:{{ contactInformation.email }}" target="_blank">{{ contactInformation.email }}</a>
      </div>
    }
    @if (contactInformation.phone) {
      <div>
        <a href="tel:{{ contactInformation.phone }}" target="_blank">{{ contactInformation.phone }}</a>
      </div>
    }
  </div>
}
